  .Cards {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 20px;
    margin-bottom: 40px;
  }
  
  .Cards img {
    width: 100%;
    margin: 10px 0;
  }


@media(max-width: 1100px) {
    .Cards {
      grid-template-columns: repeat(3, 1fr);
    }
}
  @media(max-width: 500px) {
    .Cards {
      grid-template-columns: repeat(2, 1fr);
    }

    .h1 {
        font-size: 10px;
    }
}